.nav_mainContainer{
    width: 100%;
    background-color: #5a708c;
    color: whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
ul{
    list-style: none;
    display: flex;
    gap: 13%;
    width: 15%;
    padding-right: 43px;
}
.nav_logo{
    font-size: 2em;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
}
li a{
    text-decoration: none;
    color: whitesmoke;

}
.nav_items{
    margin-top: -4px; /*Fix for navbar items not lining up with logo*/
    cursor: pointer;
    font-size: 1.4em;
    width: 40%;
    transition: all 1s ease-in-out ;
}
/*Hover effect on the navigation bar*/
li a:hover{
    color: #8bc7e5 !important;
}