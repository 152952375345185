.detail_container{
    width: 100%;
    height: calc(100vh - 74.4px);
    background-color: #657a95;
    display: grid;  
    place-items: center;
}
.detail_mainContainer{
    padding: 1% 4%;
    width: 80%;
    height: 80%;
    border-radius: 28px;
    background: #657a95;
    box-shadow:  7px 7px 14px #414e5f,
                 -7px -7px 14px #89a6cb;
    
}
table{
    width: 100%;
    text-align: left;
    font-size: 1em;
    color: white;
    
}
table tr{
    padding: 1.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.details{
    width: 100%;
    height: 438px;
    overflow: auto;
}
.details::-webkit-scrollbar{
    display: none;
}
td{
    word-break: break-word;
    font-weight: 100;
    color: #000000;
}


